<template>

    <div class="flex flex-col">

        <div class="bg-white p-8">

            <div class="">
                <div class="container mx-auto px-4">
                    <h1 class="text-4xl font-bold text-gray-800 mb-2">{{blogdetails.title}}</h1>
                    <!-- <p class="text-gray-600">Mar 16, 2020
                        <span aria-hidden="true"> / </span>
                        <span> 06 min read</span>
                    </p> -->
                </div>
            </div>
            <div class="ma-3 flex items-center">
                <div class="flex-shrink-0">
                    <a href="#">
                        <span class="sr-only">{{ blogdetails.user.lastname }}</span>
                        <img class="h-10 w-10 rounded-full" :src="require('@/assets/images/avatars/profil.jpg')" alt="">
                    </a>
                </div>
                <div class="ml-3">
                    <div class="font-medium text-gray-900">
                        <a href="#" class="hover:underline">{{ blogdetails.user.lastname }}</a>
                    </div>
                    <div class="flex space-x-1 text-gray-500">
                        <time datetime="2020-03-16">{{ blogdetails.created_at }}</time>
                        <span aria-hidden="true">·</span>
                        <span v-if="blogdetails.reading_time">{{ blogdetails.reading_time.minutes ?
                            blogdetails.reading_time.minutes + '' +
                            blogdetails.reading_time.secondes:blogdetails.reading_time.secondes }} read</span>
                    </div>
                </div>
            </div>
            <!-- <h1 class="text-4xl font-bold text-gray-800 mb-2 ml-2">Boost your conversion rate</h1> -->

            <div class="container mx-auto flex flex-col md:flex-row">
                <div class="w-full md:w-3/4">
                    <img :src="require('@/assets/images/bgs/profile-bnr.jpg')" alt="Blog Featured Image"
                        class="mb-8 h-160 w-full object-cover">
                    <div class="prose max-w-none">
                        <p v-html="blogdetails.content">
                        </p>
                    </div>
                </div>

                <div class="w-full md:w-1/4 px-4">
                    <div class="bg-gray-100 pa-4">
                        <h2 class="text-xl font-bold text-gray-800 mb-4">Recent Posts</h2>
                        <div class="list-none">
                            <blog-sample  v-for="(postblog, i) in recentblog" :key="i" :blog="postblog" class="mb-4"></blog-sample>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiCamera, mdiSend, mdiCloseThick } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import BlogSample from '@/views/dashboard/blog/components//BlogSample.vue';

export default {
    components: {
        BlogSample
    },
    data() {
        return {
            blogdetails: {},
            recentblog: []
        }
    },
    mounted() {
        Drequest.api(`lazyloading.post?dfilters=on&id:neq=${this.$route.params.keyword}&next=1&per_page=3`)
            .get((response) => {
                if (response.success === true) {
                    
                    response.listEntity.forEach((value, index) => {
                            this.recentblog.push(value)
                        })
                }
                else {
                }
            })
            .catch((err) => {
            });
    },
    beforeMount() {
        Drequest.api(`lazyloading.post?dfilters=on&id:eq=${this.$route.params.keyword}&next=1&per_page=1`)
            .get((response) => {
                if (response.success === true) {
                    this.blogdetails = response.listEntity[0]
                    console.log(this.blogdetails)
                }
                else {
                }
            })
            .catch((err) => {
            });
    },
    setup(props) { }
}
</script>